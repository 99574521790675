import React from "react";
import { connect } from "react-redux";
import TwilioConfig from "../TwilioConfig";
import {
  contactsName,
  formatDuration,
  formatPhoneNumber,
  isNumber,
} from "../helpers";
import { fetchContactData } from "../actions/contacts";
import { ReactComponent as GreenDot } from "../assets/img/icons-new/general/green-dot/green-dot.svg";
import { ReactComponent as CallExpandIcon } from "../assets/img/icons-new/call-expand/call-expand-white.svg";
import { setOneToOneCallingId } from "../actions/threads";
import { terminateHoldCall } from "../actions/calls";

function mapStateToProps(store, ownProps) {
  const contactId = store.twilio.callContactId;
  let contactData = null;
  let contactStatus = null;

  if (contactId) {
    let contact =
      typeof store.contacts.data[contactId] === "undefined"
        ? null
        : store.contacts.data[contactId];
    if (contact) {
      contactData = [];
      for (let i = 0; i < contact.contacts_ids.length; i++) {
        if (
          typeof store.contacts.data[contact.contacts_ids[i]] !== "undefined"
        ) {
          contactData.push(store.contacts.data[contact.contacts_ids[i]]);
        }
      }
    }
    contactStatus =
      typeof store.contacts.dataStatus[contactId] === "undefined"
        ? null
        : store.contacts.dataStatus[contactId];
  }

  return {
    companyId: store.companies.currentCompany.id,
    contactId: contactId,
    contactData: contactData,
    contactStatus: contactStatus,
    callStatus: store.twilio.callStatus,
    callStartedAt: store.twilio.callStartedAt,
    callMinutePrice: store.twilio.callMinutePrice,
    outgoingCallParams: store.twilio.outgoingCallParams,
    isCallOnHold: store.calls.isCallOnHold,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchContactData: (companyId, contactId) =>
      dispatch(fetchContactData(companyId, contactId)),
    setOneToOneCallingId: (callingId) =>
      dispatch(setOneToOneCallingId(callingId)),
    terminateHoldCall: (data) => dispatch(terminateHoldCall(data)),
  };
}

class CallMinimizeBar extends React.Component {
  constructor(props) {
    super(props);
    this.timeInterval = null;
    this.updateData = this.updateData.bind(this);
    this.decline = this.decline.bind(this);
    this.state = {
      time: 0,
      spent: 0,
    };
  }

  componentDidMount() {
    const { contactId, companyId } = this.props;
    if (this.props.contactStatus == null && contactId) {
      this.props.fetchContactData(companyId, contactId);
    }
    this.timeInterval = setInterval(this.updateData, 1000);
    document.body.classList.add("has-minimize-bar");
  }

  componentWillUnmount() {
    clearInterval(this.timeInterval);
    document.body.classList.remove("has-minimize-bar");
  }

  updateData() {
    const time = this.props.callStartedAt
      ? Math.ceil((new Date().getTime() - this.props.callStartedAt) / 1000)
      : 0;
    const spent = this.props.callStartedAt
      ? Math.ceil(time / 60) * this.props.callMinutePrice
      : 0;
    this.setState({
      time: time,
      spent: spent,
    });
  }

  decline(e) {
    const { companyId, isCallOnHold, outgoingCallParams, terminateHoldCall } =
      this.props;
    e && e.preventDefault();
    e && e.stopPropagation();
    TwilioConfig.disconnect();
    if (isCallOnHold) {
      terminateHoldCall({
        companyId: companyId,
        sid: outgoingCallParams?.CallSid,
      });
    }
  }

  render() {
    const { setOneToOneCallingId, callStatus } = this.props;
    const name = this.props.contactData
      ? contactsName(this.props.contactData, false, 4, true)
      : "";
    const phonecode =
      this.props.contactData && this.props.contactData[0].country
        ? "" + this.props.contactData[0].country.phonecode
        : "";
    const number = this.props.contactData
      ? formatPhoneNumber(
          "" + phonecode + this.props.contactData[0].phone_number
        )
      : "";
    return (
      <div className="call-minimize-bar top-banner-wrapper w-100">
        <div className="detail flexer-row flex-wrap">
          {callStatus === "connecting" && (
            <span className="calling-details">
              Calling to {!isNumber(name) && <span>{name}</span>}{" "}
              <span className="ml-1">{number}</span>
            </span>
          )}

          {callStatus === "connected" && (
            <span className="calling-details">
              <GreenDot /> On Call (Outgoing) with{" "}
              {!isNumber(name) && <span>{name}</span>}{" "}
              <span className="ml-1">{number}</span>
            </span>
          )}
          <span className="duratiion-details">
            {formatDuration(this.state.time)}
          </span>
          <span>You spent {this.state.spent} credits</span>
        </div>
        <div className="right-action flexer-row">
          <CallExpandIcon
            onClick={() => setOneToOneCallingId(this.props.contactId)}
            className="mr-3 cursor-pointer"
            width={12}
            height={12}
          />
          <a href="#void" className="decline" onClick={this.decline}>
            <i className="material-icons">call_end</i>
          </a>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CallMinimizeBar);
